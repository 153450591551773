<template>
  <c-modal
    :is-open="isOpen"
    :on-close="close"
    :size="['sm', 'md'].includes(currentBreakpoint) ? 'md' : 'xl'"
    :initial-focus-ref="() => {}"
    :close-on-overlay-click="false"
  >
    <c-modal-content
      ref="content"
      :border-radius="['8px', '16px']"
    >
      <c-modal-close-button
        size="sm"
        color="white"
        border-radius="100%"
        background-color="danger.400"
        :_hover="{ backgroundColor: 'danger.500' }"
        :_active="{ backgroundColor: 'danger.600' }"
        :_focus="{ boxShadow: 'outline' }"
      />
      <c-modal-body
        text-align="center"
        padding="30px 30px 0 30px"
      >
        <c-box
          :min-width="['150px', '200px']"
          :min-height="['150px', '200px']"
          :width="['150px', '200px']"
          :height="['150px', '200px']"
          margin="auto"
          :margin-bottom="['20px', '10px']"
        >
          <inline-svg
            :src="require('@/assets/images/image-email-checked.svg')"
            width="100%"
            height="100%"
          />
        </c-box>

        <BaseText
          size-mobile="20px"
          size-desktop="28px"
          color="primary.400"
          :margin-bottom="['4px', '10px']"
        >
          Kode OTP telah dikirim!
        </BaseText>

        <BaseText
          size-mobile="14px-2"
          size-desktop="16px"
          :margin-bottom="['20px', '6.5px']"
        >
          Silahkan cek email
        </BaseText>

        <c-box
          text-align="start"
          :margin-bottom="['20px', '40px']"
        >
          <FormInput
            v-model="otp"
            type="number"
            is-required
            :is-invalid="dataMixinsModalOtp.isValidOtp === false"
            label="Kode OTP"
            placeholder="Masukkan kode OTP"
            :icon-left-element="require('@/assets/ic-padlock.svg')"
            full-width
            :is-helper="dataMixinsModalOtp.isValidOtp === false"
            helper-text="Kode OTP yang anda masukkan salah"
            helper-text-type="warn"
            helper-text-color="#D32737"
          />
        </c-box>

        <c-box
          :margin-bottom="['26.5px', '30px']"
        >
          <BaseText
            size-mobile="14px-2"
            size-desktop="16px"
            margin-right="16px"
            display="inline-block"
          >
            Saya tidak mendapatkan email
          </BaseText>

          <BaseText
            size-mobile="14px-2"
            size-desktop="16px"
            :color="dataMixinsModalOtp.countDownStatus === 'running' ? 'inherit' : 'primary.400'"
            :as="dataMixinsModalOtp.countDownStatus === 'running' ? 'span' : 'a'"
            @click="handleResendOtp"
          >
            {{ dataMixinsModalOtp.countDownStatus === "running" ? formatStopwatch(dataMixinsModalOtp.countDown * 1000) : "Kirim ulang" }}
          </BaseText>
        </c-box>
      </c-modal-body>
      <c-modal-footer
        d="block"
        text-align="center"
        padding="0 30px 30px 30px"
      >
        <BaseButton
          size="medium"
          variant="contained"
          w="100%"
          :max-width="['130px','420px']"
          border-radius="1000px"
          :disabled="$v.$invalid"
          :is-loading="isLoadingEmailOtpVerify"
          @click="handleVerifyOtp"
        >
          Verifikasi
        </BaseButton>
      </c-modal-footer>
    </c-modal-content>
    <c-modal-overlay />
  </c-modal>
</template>

<script>
import BaseText from '@/components/elements/base-text.vue'
import BaseButton from '@/components/elements/base-button.vue'
import FormInput from '@/views/client/kuisioner/forms/form-input.vue'
import { required } from 'vuelidate/lib/validators'
import mixinsCheckBreakpoints from '@/mixins/mixins-check-breakpoints'
import mixinsModalOtp from '@/views/buy/_widgets/mixins-modal-otp'
import { formatStopwatch } from '@/utils/format-stopwatch'

export default {
  name: 'ModalOtp',
  components: { FormInput, BaseButton, BaseText },
  mixins: [mixinsCheckBreakpoints(), mixinsModalOtp],
  props: {
    isOpen: {
      type: Boolean,
      required: false,
    },
  },
  data() {
    return {
      otp: '',
    }
  },
  validations: {
    otp: { required },
  },
  methods: {
    formatStopwatch,
    close() {
      this.otp = ''
      this.$emit('handle-is-open', false)
    },
    handleResendOtp() {
      this.handleEmailOtpResend({
        email: this.dataMixinsModalOtp.email,
      })
    },
    handleVerifyOtp() {
      this.handleEmailOtpVerify({
        email: this.dataMixinsModalOtp.email,
        otp: this.otp,
      }, (isSucces) => {
        this.$emit('trigger-result-verify', isSucces)
      })
    },
  },
}
</script>