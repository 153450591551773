var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('c-modal', {
    attrs: {
      "is-open": _vm.isOpen,
      "on-close": _vm.close,
      "size": ['sm', 'md'].includes(_vm.currentBreakpoint) ? 'md' : 'xl',
      "initial-focus-ref": function initialFocusRef() {},
      "close-on-overlay-click": false
    }
  }, [_c('c-modal-content', {
    ref: "content",
    attrs: {
      "border-radius": ['8px', '16px']
    }
  }, [_c('c-modal-close-button', {
    attrs: {
      "size": "sm",
      "color": "white",
      "border-radius": "100%",
      "background-color": "danger.400",
      "_hover": {
        backgroundColor: 'danger.500'
      },
      "_active": {
        backgroundColor: 'danger.600'
      },
      "_focus": {
        boxShadow: 'outline'
      }
    }
  }), _c('c-modal-body', {
    attrs: {
      "text-align": "center",
      "padding": "30px 30px 0 30px"
    }
  }, [_c('c-box', {
    attrs: {
      "min-width": ['150px', '200px'],
      "min-height": ['150px', '200px'],
      "width": ['150px', '200px'],
      "height": ['150px', '200px'],
      "margin": "auto",
      "margin-bottom": ['20px', '10px']
    }
  }, [_c('inline-svg', {
    attrs: {
      "src": require('@/assets/images/image-email-checked.svg'),
      "width": "100%",
      "height": "100%"
    }
  })], 1), _c('BaseText', {
    attrs: {
      "size-mobile": "20px",
      "size-desktop": "28px",
      "color": "primary.400",
      "margin-bottom": ['4px', '10px']
    }
  }, [_vm._v(" Kode OTP telah dikirim! ")]), _c('BaseText', {
    attrs: {
      "size-mobile": "14px-2",
      "size-desktop": "16px",
      "margin-bottom": ['20px', '6.5px']
    }
  }, [_vm._v(" Silahkan cek email ")]), _c('c-box', {
    attrs: {
      "text-align": "start",
      "margin-bottom": ['20px', '40px']
    }
  }, [_c('FormInput', {
    attrs: {
      "type": "number",
      "is-required": "",
      "is-invalid": _vm.dataMixinsModalOtp.isValidOtp === false,
      "label": "Kode OTP",
      "placeholder": "Masukkan kode OTP",
      "icon-left-element": require('@/assets/ic-padlock.svg'),
      "full-width": "",
      "is-helper": _vm.dataMixinsModalOtp.isValidOtp === false,
      "helper-text": "Kode OTP yang anda masukkan salah",
      "helper-text-type": "warn",
      "helper-text-color": "#D32737"
    },
    model: {
      value: _vm.otp,
      callback: function callback($$v) {
        _vm.otp = $$v;
      },
      expression: "otp"
    }
  })], 1), _c('c-box', {
    attrs: {
      "margin-bottom": ['26.5px', '30px']
    }
  }, [_c('BaseText', {
    attrs: {
      "size-mobile": "14px-2",
      "size-desktop": "16px",
      "margin-right": "16px",
      "display": "inline-block"
    }
  }, [_vm._v(" Saya tidak mendapatkan email ")]), _c('BaseText', {
    attrs: {
      "size-mobile": "14px-2",
      "size-desktop": "16px",
      "color": _vm.dataMixinsModalOtp.countDownStatus === 'running' ? 'inherit' : 'primary.400',
      "as": _vm.dataMixinsModalOtp.countDownStatus === 'running' ? 'span' : 'a'
    },
    on: {
      "click": _vm.handleResendOtp
    }
  }, [_vm._v(" " + _vm._s(_vm.dataMixinsModalOtp.countDownStatus === "running" ? _vm.formatStopwatch(_vm.dataMixinsModalOtp.countDown * 1000) : "Kirim ulang") + " ")])], 1)], 1), _c('c-modal-footer', {
    attrs: {
      "d": "block",
      "text-align": "center",
      "padding": "0 30px 30px 30px"
    }
  }, [_c('BaseButton', {
    attrs: {
      "size": "medium",
      "variant": "contained",
      "w": "100%",
      "max-width": ['130px', '420px'],
      "border-radius": "1000px",
      "disabled": _vm.$v.$invalid,
      "is-loading": _vm.isLoadingEmailOtpVerify
    },
    on: {
      "click": _vm.handleVerifyOtp
    }
  }, [_vm._v(" Verifikasi ")])], 1)], 1), _c('c-modal-overlay')], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }