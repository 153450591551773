var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('c-modal', {
    attrs: {
      "is-open": _vm.isOpen,
      "on-close": _vm.close,
      "size": ['sm', 'md'].includes(_vm.currentBreakpoint) ? 'md' : 'xl',
      "initial-focus-ref": function initialFocusRef() {},
      "final-focus-ref": function finalFocusRef() {}
    }
  }, [_c('c-modal-content', {
    ref: "content",
    attrs: {
      "border-radius": ['8px', '16px']
    }
  }, [_c('c-modal-body', {
    attrs: {
      "width": "100%",
      "max-width": "430px",
      "margin": "auto",
      "padding": "30px 30px 0 30px"
    }
  }, [_c('BaseText', {
    attrs: {
      "size-mobile": "20px",
      "size-desktop": "28px",
      "color": "primary.400",
      "text-align": "center",
      "margin-bottom": ['20px', '30px']
    }
  }, [_vm._v(" Login ")]), _c('FormInput', {
    attrs: {
      "type": "text",
      "is-required": "",
      "is-invalid": _vm.isInvalidField(_vm.$v.email),
      "label": "Email",
      "placeholder": "Masukkan email",
      "icon-left-element": require('@/assets/ic-mail.svg'),
      "icon-left-element-color": "#888888",
      "full-width": ""
    },
    on: {
      "blur": _vm.$v.email.$touch
    },
    model: {
      value: _vm.email,
      callback: function callback($$v) {
        _vm.email = $$v;
      },
      expression: "email"
    }
  }), _c('FormInput', {
    attrs: {
      "type": "password",
      "is-required": "",
      "is-invalid": _vm.isInvalidField(_vm.$v.password),
      "label": "Kata Sandi",
      "placeholder": "Masukkan kata sandi",
      "icon-left-element": require('@/assets/ic-padlock.svg'),
      "full-width": ""
    },
    on: {
      "blur": _vm.$v.password.$touch
    },
    model: {
      value: _vm.password,
      callback: function callback($$v) {
        _vm.password = $$v;
      },
      expression: "password"
    }
  })], 1), _c('c-modal-footer', {
    attrs: {
      "d": "block",
      "padding": "0 30px 30px 30px",
      "width": "100%",
      "max-width": "420px",
      "margin": "auto"
    }
  }, [_c('BaseButton', {
    attrs: {
      "size": "medium",
      "variant": "contained",
      "w": "100%",
      "border-radius": "1000px",
      "disabled": _vm.$v.$invalid,
      "is-loading": _vm.mixinsUseRequest_data.handleLogin.isLoading
    },
    on: {
      "click": _vm.handleLogin
    }
  }, [_vm._v(" Masuk ")]), _c('BaseDivider', {
    attrs: {
      "text": "atau",
      "margin": ['22px 0', '20px 0']
    }
  }), _c('BaseButton', {
    attrs: {
      "size": "medium",
      "variant": "outlined",
      "w": "100%",
      "border-radius": "1000px",
      "left-svg-icon": require('@/assets/ic-google.svg'),
      "is-loading": _vm.dataMixinsLogin.isLoadingLoginWithGoogle
    },
    on: {
      "click": _vm.handleGoogle_
    }
  }, [_vm._v(" Masuk dengan Google ")])], 1)], 1), _c('c-modal-overlay')], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }